import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { switchMap, map} from 'rxjs/operators';
import { ApiService } from '../firebase/api.service';
import { ToastMessages } from '../helplers/toast';


export interface User {
  uid: string;
  email: string;
}

export interface Message {
  date: firebase.default.firestore.FieldValue;
  id: string;
  senderId: string;
  message: string;
  fromName: string;
  myMsg: boolean;
  formattedDate: Date;
}

@Injectable({
providedIn: 'root'
})
export class ChatService{
  currentUser: User = null;

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private apiService: ApiService) {
    this.afAuth.onAuthStateChanged(user =>{

      this.currentUser = user;
    });
  }

async sigUp({email, password}) {
const credential = await this.afAuth.createUserWithEmailAndPassword(
  email,
  password
);

const uid = credential.user.uid;

return this.afs.doc(
  'user/${uid}'
).set({
  uid,
  email:credential.user.email,
});
}

// signIn({email, password}){
//   return this.afAuth.signInWithEmailAndPassword (email, password);

// }

// signOut(){
//   return this.afAuth.signOut();
// }

addChatMessage(message: string, collection: string) {
  console.log('ADDING MESSAGE');
  return this.afs.collection(collection).add({
    message,
    senderId: this.apiService.getCurrentUser().uid,
    date: firebase.default.firestore.FieldValue.serverTimestamp()

  });
}


getChatMessages(collection: string){
  let users = [];
  return this.getUsers().pipe(
    switchMap(res => {
      users = res;

      return this.afs.collection(collection, ref => ref.orderBy ('date')).valueChanges({idField: 'id'}) as Observable<Message[]>;
    }),
    map(messages => {
      for (const m of messages){
       m.fromName = this.getUserForMsg(m.senderId, users);
        m.myMsg = this. currentUser.uid === m.senderId;
        if((m.date as any) != null){
          m.formattedDate =(m.date as any).toDate();
          }else{m.formattedDate = new Date();}
        }
      ;
      return messages;
    })

  );
}


getUsers() {
  return this.afs.collection('users').valueChanges({idField: 'uid'}) as Observable<User[]>;
}

getUserForMsg(msgFromId, users: User[]): string {
  for (const usr of users) {
    if (usr.uid === msgFromId) {
      return usr.email;
    }
  }
  return 'Unknown';
}
}
