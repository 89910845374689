import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class ToastMessages {
  toast(message: string, duration = 2000){
  const toast = document.createElement('ion-toast');
  toast.message = message;
  toast.duration = duration;

  document.body.appendChild(toast);
  return toast.present();
}
}
